import { post } from '@/utils/request'

//获取会员列表
export function getList(data) {
    return post('/admin/user/userList', data)
}
// 设为超级会员
export function setSvip(data) {
    return post('/admin/user/setUserSvip', data)
}
// 设为会员
export function setVip(data) {
    return post('/admin/user/setUserVip', data)
}
//修改
export function getEdit(data) {
    return post('admin/park/edit', data)
}
//添加
export function getAdd(data) {
    return post('admin/member/addMeber', data)
}
//设置状态
export function getState(data) {
    return post('admin/mlist/editMember', data)
}
//手机号验证
export function sendCode(data) {
    return post('admin/member/sendCode', data)
}

// 添加会员等级
export function addGrade(data) {
    return post('/admin/grade/addGrade', data)
}
// 会员等级列表
export function gradeList(data) {
    return post('/admin/grade/gradeList', data)
}

// 会员等级详情

export function gradeInfo(data) {
    return post('/admin/grade/gradeInfo', data)
}

// 编辑会员等级
export function editGrade(data) {
    return post('/admin/grade/editGrade', data)
}

// 删除会员等级
export function delgrade(data) {
    return post('/admin/grade/delgrade', data)
}
// 会员等级列表
export function selGrade(data) {
    return post('/admin/grade/selGrade', data)
}
// 添加会员折扣
export function addDiscount(data) {
    return post('/admin/grade/addDiscount', data)
}
// 会员折扣列表 
export function discountList(data) {
    return post('/admin/grade/discountList', data)
}
// 编辑会员折扣
export function editDiscount(data) {
    return post('/admin/grade/editDiscount', data)
}
// 会员折扣详情
export function discountInfo(data) {
    return post('/admin/grade/discountInfo', data)
}
// 删除会员折扣
export function delDiscount(data) {
    return post('/admin/grade/delDiscount', data)
}
// 获取店铺列表
export function storeList(data) {
    return post('/admin/merchant/listMerchant', data)
}
//根据店铺id获取商品列表 

export function getGoodsList(data) {
    return post('/psi/coupon/goodsList', data)
}
// 店铺商品分类
export function getShopClass(data) {
    return post('/admin/inventory/getShopClass', data)
}