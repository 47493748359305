<template>
  <div>
    <el-card>
      <div class="menu-box">
        <h2>会员折扣管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-button type="primary" @click="pAdd()">添加会员折扣</el-button>
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
          >
            <el-table-column prop="discount" label="折扣幅度">
            </el-table-column>
            <el-table-column width="150" label="分类">
              <template v-slot="scope">
                <el-tag
                  v-for="item in scope.row.class_arr"
                  :key="item.class2_id"
                  >{{ item.class1_name }} / {{ item.class2_name }}</el-tag
                >
              </template>
            </el-table-column>

            <el-table-column prop="shop_name" label="所属店铺">
            </el-table-column>

            <el-table-column prop="class_status" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.status === 1">
                  启用
                </el-tag>
                <el-tag type="warning" v-if="scope.row.status === 0">
                  禁用
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :pager-count="15"
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.limit"
            background
            :total="exp.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 添加/删除对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="formClose"
    >
      <el-form :rules="formRules" ref="formRef" :model="form">
        <el-form-item
          prop="discount"
          label="会员折扣"
          :label-width="formLabelWidth"
        >
          <el-input
            type="number"
            @change="handleChange"
            v-model="form.discount"
            placeholder="请填写会员折扣"
          ></el-input>
        </el-form-item>

        <el-form-item label="店铺" :label-width="formLabelWidth">
          <el-select
            @change="handleStoreChange"
            v-model="form.shop_id"
            style="width: 100%"
            placeholder="请选择店铺"
            clearable
          >
            <el-option
              v-for="item in shopList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="分类"
          prop="class_id"
          v-if="form.shop_id"
          :label-width="formLabelWidth"
        >
          <el-cascader
            style="width: 100%"
            placeholder="请选择分类"
            :options="typeList"
            :props="optionProps"
            v-model="form.class_id"
            clearable
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="会员等级" :label-width="formLabelWidth">
          <el-select
            @change="handleChange"
            v-model="form.grade_id"
            style="width: 100%"
            placeholder="请选择会员等级"
            clearable
          >
            <el-option
              v-for="item in levelList"
              :key="item.grade_id"
              :label="item.name"
              :value="item.grade_id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="addOrEdit === 2"
          prop="status"
          label="状态"
          :label-width="formLabelWidth"
        >
          <el-switch
            @change="handleChange"
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="confirmAE()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <all-log :dialogLog="dialogLog" />
  </div>
</template>

<script>
import lodash from 'lodash'
import {
  selGrade,
  addDiscount,
  discountList,
  editDiscount,
  discountInfo,
  delDiscount,
  storeList,
  getGoodsList,
  getShopClass,
} from '@/api/member/listMember.js'
import { add, getList, getInfo, edit, del } from '@/api/room/roomgroup.js'
export default {
  data() {
    return {
      imgList: [],
      dialogLog: {
        state: false,
      },
      isEdit: false,
      tableLoading: false,
      warningText: '',
      exp: {
        total: 0,
      },
      optionProps: {
        value: 'id',
        label: 'name',
        children: 'son',
        multiple: true,
      },
      shopList: [],
      btnLoading: false,

      query: {
        page: 1,
        page_code: '',
        limit: 7,
      },

      dialogVisible: false,
      changeDialogVisible: false,
      formRules: {
        name: {
          required: true,
          message: '请填写会员折扣名称',
          trigger: 'blur',
        },
        shop_id: {
          required: true,
          message: '请选择店铺',
          trigger: 'blur',
        },
      },
      title: '添加会员折扣',
      addOrEdit: 1,
      formLabelWidth: '160px',

      form: {
        shop_id: '',

        status: '',

        class_id: [],
        goods_id: [],
        discount: '',
      },
      tableData: [],
      levelList: [],
      typeList: [],
    }
  },
  created() {
    this.getList()
    this.getOptionList()
  },
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl
    },
  },
  methods: {
    async handleStoreChange() {
      this.isEdit = true
      const { data: res } = await getShopClass({ app_id: this.form.shop_id })
      console.log(res)
      if (res.code === 1) {
        this.typeList = res.data
      } else {
        this.$message.error(res.message)
      }
    },
    async getOptionList() {
      const { data: res } = await storeList()
      console.log(res)
      if (res.code === 0) {
        this.shopList = res.data
      } else {
        this.$message.error('获取店铺数据失败！')
      }
      const { data: res1 } = await selGrade()
      console.log(res1)
      if (res1.code === 1) {
        this.levelList = res1.data
      } else {
        this.$message.error('获取会员列表失败！')
      }
    },

    pageChange(val) {
      this.query.page = val
      this.getList()
    },
    sizeChange(val) {
      this.query.limit = val
      this.getList()
    },
    async getList() {
      this.tableLoading = true
      const { data: res } = await discountList(this.query)
      console.log(res)
      if (res.code === 1) {
        this.tableData = res.data.data
        this.exp.total = res.data.total
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancelAE() {
      this.title = '添加会员折扣'
      this.addOrEdit = 1
      this.isEdit = false
      this.form = {
        shop_id: '',

        status: '',

        class_id: [],
        goods_id: [],
        discount: '',
      }

      if (this.$refs.formRef) {
        setTimeout(() => {
          this.$refs.formRef.clearValidate()
        }, 30)
      }

      this.dialogVisible = false
    },

    // 新增打开
    pAdd(row) {
      console.log(row)

      this.dialogVisible = true
    },
    // 编辑打开
    async handleEdit(row) {
      console.log(row)
      this.dialogVisible = true
      this.title = '编辑会员折扣'
      this.addOrEdit = 2
      const { data: res } = await discountInfo({
        discount_id: row.discount_id,
      })
      console.log(res.data)
      //   this.form.class_name = res.data.class_name
      //   this.form.class_status = res.data.class_status
      this.form.discount_id = res.data.discount_id
      this.form.discount = res.data.discount
      this.form.grade_id = res.data.grade_id
      this.form.shop_id = Number(res.data.shop_id)
      this.form.status = res.data.status
      // 处理级联选择框回显
      this.handleStoreChange().then(() => {
        if (res.data.class_id && res.data.class_id.length > 0) {
          console.log('class_id')
          let ids = []
          for (let i = 0; i < res.data.class_id.length; i++) {
            ids.push(Number(res.data.class_id[i]))
          }
          console.log(ids)
          let newArr = []
          ids.forEach((id) => {
            newArr.push(this.cascaderRepaint(id, this.typeList))
          })
          this.form.class_id = newArr
        }
      })
    },
    cascaderRepaint(key, treeData) {
      console.log(treeData)
      let arr = [] // 在递归时操作的数组
      let returnArr = [] // 存放结果的数组
      let depth = 0 // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1) //将目前匹配的数组，截断并保存到结果数组，
            break
          } else {
            if (childrenData[j].son) {
              depth++
              childrenEach(childrenData[j].son, depth)
            }
          }
        }
        return returnArr
      }
      return childrenEach(treeData, depth)
    },
    // 删除
    remove(row) {
      this.$confirm('确认删除？')
        .then(async (_) => {
          const { data: res } = await delDiscount({
            discount_id: row.discount_id,
          })
          if (res.code === 1) {
            this.$message.success('删除成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    confirmAE(e) {
      console.log(e)
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit === 1) {
            // 添加
            let class_ids = []

            this.form.class_id.forEach((item) => {
              let length = item.length
              let id = item[length - 1]
              class_ids.push(id)
            })
            let query = lodash.cloneDeep(this.form)

            query.class_id = JSON.stringify(class_ids)
            console.log(query)
            let formData = new FormData()
            for (let key in query) {
              formData.append(key, query[key])
            }

            this.btnLoading = true
            const { data: res } = await addDiscount(formData)
            if (res.code === 1) {
              this.$message.success('添加成功')
              this.getList()
              this.cancelAE()
            } else {
              this.$message.error(res.message)
            }
            this.btnLoading = false
          } else {
            // 编辑
            let class_ids = []

            this.form.class_id.forEach((item) => {
              let length = item.length
              let id = item[length - 1]
              class_ids.push(id)
            })
            let query = lodash.cloneDeep(this.form)

            query.class_id = JSON.stringify(class_ids)
            console.log(query)
            let formData = new FormData()
            for (let key in query) {
              formData.append(key, query[key])
            }

            this.btnLoading = true
            if (this.isEdit === true) {
              const { data: res } = await editDiscount(formData)
              if (res.code === 1) {
                this.$message.success('编辑成功')
                this.cancelAE()
                this.getList()
              } else {
                this.$message.error(res.message)
              }
            } else {
              this.$message.info('没有修改任何数据')
            }
            this.btnLoading = false
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../assets/exclamationmark.png') no-repeat;
}
</style>
