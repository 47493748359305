import { post } from '@/utils/request'


export function add(data) {
    return post('/room/room_group/add', data)
}


export function getList(data) {
    return post('/room/room_group/list', data)
}

export function getInfo(data) {
    return post('/room/room_group/info', data)
}

export function edit(data) {
    return post('/room/room_group/edit', data)
}

export function del(data) {
    return post('/room/room_group/delete', data)
}

export function getStoreList(data) {
    return post('/room/room_group/shopList', data)
}